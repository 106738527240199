// https://www.figma.com/design/XQvkrpDigTM6QIU8huKKAa/blog-(Copy)?node-id=780-16817&t=7bgTsIeisfKsqbeX-0

import React from 'react';
import './App.css'; // Ensure Tailwind is imported here if not in index.js
import BlogCard from './blogCard';

const cardData = [
	{
		title: "Curbing AI creativity: How to provide effective guardrails",
		date: "June 10, 2024",
		summary: "Learn how to maintain control over AI creativity. Discover techniques to ensure the outputs of your chatbot align with your objectives.",
		imageUrl: "/Component1.png",
		tag: ['Branding']
	},
	{
		title: "Is OSO, organic search optimization, the new SEO?",
		date: "June 11, 2024",
		summary: "Have you heard about OSO? Discover how it differs from your usual SEO strategy in our latest post.",
		imageUrl: "/Component2.png",
		tag: ['Branding', 'Social Media', 'Twitter']
	},
	{
		title: "Is OSO, organic search optimization, the new SEO?",
		date: "June 11, 2024",
		summary: "Have you heard about OSO? Discover how it differs from your usual SEO strategy in our latest post.",
		imageUrl: "/Component2.png",
		tag: ['Branding', 'Social Media', 'Twitter']
	},
	{
		title: "Curbing AI creativity: How to provide effective guardrails",
		date: "June 11, 2024",
		summary: "Learn how to maintain control over AI creativity. Discover techniques to ensure the outputs of your chatbot align with your objectives.",
		imageUrl: "/Component4.png",
		tag: ['Branding', 'Social Media', 'Twitter']
	},
	{
		title: "Curbing AI creativity: How to provide effective guardrails",
		date: "June 11, 2024",
		summary: "Learn how to maintain control over AI creativity. Discover techniques to ensure the outputs of your chatbot align with your objectives.",
		imageUrl: "/Component5.png",
		tag: ['Branding', 'Social Media', 'Twitter']
	},
	{
		title: "Is OSO, organic search optimization, the new SEO?",
		date: "June 11, 2024",
		summary: "Have you heard about OSO? Discover how it differs from your usual SEO strategy in our latest post.",
		imageUrl: "/Component2.png",
		tag: ['Branding', 'Social Media', 'Twitter']
	},
	{
		title: "Is OSO, organic search optimization, the new SEO?",
		date: "June 11, 2024",
		summary: "Have you heard about OSO? Discover how it differs from your usual SEO strategy in our latest post.",
		imageUrl: "/Component7.png",
		tag: ['Branding', 'Social Media', 'Twitter']
	},
	{
		title: "Curbing AI creativity: How to provide effective guardrails",
		date: "June 11, 2024",
		summary: "Learn how to maintain control over AI creativity. Discover techniques to ensure the outputs of your chatbot align with your objectives.",
		imageUrl: "/Component5.png",
		tag: ['Branding', 'Social Media', 'Twitter']
	},
];


const Button = ({ text, width }) => {
	return (
		<button className="bg-[#3FB181] text-white text-sm py-2 px-5 rounded-md flex items-center justify-center" style={{ width: width, height: '60px', borderRadius: '10px' }}>
			{text}
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: '10px' }}>
				<path d="M12.7273 18.7784L11.2642 17.3295L15.6463 12.9474H5V10.831H15.6463L11.2642 6.45597L12.7273 5L19.6165 11.8892L12.7273 18.7784Z" fill="white" />
			</svg>
		</button>);
};

const App = () => {
	const basePath = '/img';

	return (

		<div className="container mx-auto px-4 py-2 flex flex-col items-center justify-center">

			<div className="container mx-auto px-4 py-2 items-center hidden sm:flex justify-between">
				<div className="flex items-center space-x-4">
					<img src={'/img/logo.png'} alt="Logo" className="footer-logo" />
				</div>
				<div className=" md:flex space-x-12 font-body text-body font-normal text-custom-deepBlue">
					<a href="#features" >Features</a>
					<a href="#sellers" >Sellers</a>
					<a href="#blog" >Blog</a>
					<a href="#contacts" >Contacts</a>
				</div>
				<Button text="Start use Storly" width='203px' />
			</div>

			<h1 className="text-h5 font-semibold font-sans tracking-tight leading-tight text-center mx-auto gradient-to-r typography w-full sm:w-850 px-2.5 sm:px-0 break-words">
				Blog. Learn more about SEO, traffic and promotion with us!
			</h1>

			<div className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 p-4 mb-7">
				{cardData.map((card, index) => (
					<BlogCard
						key={index}
						title={card.title}
						date={card.date}
						summary={card.summary}
						imageUrl={`${basePath}${card.imageUrl}`}

						tag={card.tag}
					/>
				))}
			</div>


			<div className="flex justify-center space-x-2">
				<button className="px-4 py-2 bg-white hover:bg-[#F6F7F9] text-[#71798B] text-base font-medium mt-3  shadow rounded-[10px] shadow-none">1</button>
				<button className="px-4 py-2 bg-[#F6F7F9]                text-[#71798B] text-base font-medium mt-3  shadow rounded-[10px] shadow-none">2</button>
				<button className="px-4 py-2 bg-white hover:bg-[#F6F7F9] text-[#71798B] text-base font-medium mt-3  shadow rounded-[10px] shadow-none">3</button>
				<button className="px-4 py-2 bg-white hover:bg-[#F6F7F9] text-[#71798B] text-base font-medium mt-3  shadow rounded-[10px] shadow-none">4</button>
				<button className="px-4 py-2 bg-white hover:bg-[#F6F7F9] text-[#71798B] text-base font-medium mt-3  shadow rounded-[10px] shadow-none">
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6.39205 11.2102L5.36932 10.1989L8.92045 6.64773H0V5.17045H8.92045L5.36932 1.625L6.39205 0.607954L11.6932 5.90909L6.39205 11.2102Z" fill="#71798B" />
					</svg>
				</button>
			</div>


			{/* <div className="container mx-auto px-4 py-2 flex flex-col sm:grid grid-cols-3 items-start mt-12"> */}
			<div className="hidden sm:flex sm:grid grid-cols-3 items-start mt-12 container mx-auto px-4 py-2 mb-10">

				<div className="w-full sm:w-35 h-12 mb-4 sm:mb-0">
					<div className="flex justify-center sm:justify-start items-center space-x-4">
						<img src={'/img/logo.png'} alt="Logo" className="footer-logo" />
					</div>
					<p className="text-[#71798B] text-base font-medium mt-3 text-center sm:text-left">Storly 2024<br />All rights reserved</p>
				</div>

				<div className="grid grid-cols-2 gap-4 font-body text-body font-normal text-custom-deepBlue order-3 sm:order-2  items-center">
					<a href="#features">Features</a>
					<a href="#blog">Blog</a>
					<a href="#sellers">Sellers</a>
					<a href="#linkedIn">LinkedIn</a>
					<a href="#contacts">Contacts</a>
					<a href="#instagram">Instagram</a>
				</div>

				<div className="flex justify-center sm:justify-end order-2 sm:order-3 mb-4 sm:mb-0">
					<Button text="Start use Storly" width='203px' />
				</div>
			</div>

			<div className="container mx-auto px-4 py-2 flex flex-col items-start mt-12 block sm:hidden">
				<div className="w-full sm:w-35 h-12 mb-4 sm:mb-0">
					<div className="flex justify-start items-start space-x-4">
						<img src={'/img/logo.png'} alt="Logo" className="footer-logo" />
					</div>
				</div>

				<div className="grid grid-cols-2 gap-4 font-body text-body font-normal my-8">
					<a href="#features">Features</a>
					<a href="#blog">Blog</a>
					<a href="#sellers">Sellers</a>
					<a href="#linkedIn">LinkedIn</a>
					<a href="#contacts">Contacts</a>
					<a href="#instagram">Instagram</a>
				</div>

				<div className="flex justify-center w-full">
					<Button text="Start use Storly" width='100%' />
				</div>

				<p className="flex justify-center text-[#71798B] text-base font-medium my-6 mb-6">Storly 2024 // All rights reserved</p>
			</div>







		</div>





	);
}

export default App;


