import React from 'react';

function Button({ label }) {
	return (
		<button className="bg-[#F6F7F9] hover:bg-gray-300 text-[#71798B] text-base font-medium mt-3 py-2 px-4 shadow rounded-[18px] shadow-none">
			{label}
		</button>
	);
}

function BlogCard({ title, date, summary, imageUrl, tag }) {
	return (

		<div className="mx-auto bg-white rounded-xl border border-gray-300 ">
			<div className="mx-auto p-6">
				<img className="mx-auto w-full h-auto object-contain rounded-xl" src={imageUrl} alt="Blog" />

				<h1 className="mt-4 mb-2 font-body text-h4 font-semibold tracking-tight leading-tighter text-gray-900">
					{title}
				</h1>
				<div className="flex space-x-4 p-2">
					{tag.map((buttonLabel, index) => (
						<Button key={index} label={buttonLabel} />
					))}
				</div>


				<p className="font-body text-base font-medium text-align-left  text-[#71798B] p-2">{summary}</p>
				<div className="flex justify-between items-center">
					<p className="text-[#71798B] text-base font-medium mt-3">{date}</p>
					<a href="#readMore" className="flex items-center text-[#71798B] text-base font-medium mt-3 no-underline">
						Read More&nbsp;
						<svg className="ml-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.39205 11.2102L5.36932 10.1989L8.92045 6.64773H0V5.17045H8.92045L5.36932 1.625L6.39205 0.607954L11.6932 5.90909L6.39205 11.2102Z" fill="#71798B" />
						</svg>
					</a>
				</div>
			</div>
		</div>
	);
}

export default BlogCard;
